function Card(props) {
  return (
    <div className="card">
      <div className="p-3">
        <p className="card-text">{props.text}</p>
      </div>
    </div>
  );
}
export default Card;
