import Card from "./Card";
function Services() {
  return (
    <div className="container services" style={{ paddingTop: "4rem" }}>
      <h2 className="main-title text-center">주요 업무</h2>
      <div className="card-cover row">
        <div className="col-md-6">
          <div className="mb-2">
            <Card text="지역 특성을 반영한 대중교통체계개편(준공영제) 실행방안 수립" />
          </div>
          <div className="mb-2">
            <Card text="준공영제 시행을 위한 법·제도 정비방안 수립" />
          </div>

          <div className="mb-2">
            <Card text="버스업체 회계감사를 통한 표준회계처리 기준 수립" />
          </div>

          <div className="mb-2">
            <Card text="대중교통이용 활성화 및 운영효율성 증진을 위한 조사 분석" />
          </div>

          <div className="mb-2">
            <Card text="교통약자 이동편의증진을 위한 정책방향 및 제도개선 방안 연구" />
          </div>

          <div className="mb-2">
            <Card text="친환경 교통수단(전기·수소차량)의 합리적인 운영방안 연구" />
          </div>
        </div>

        <div className="col-md-6" style={{ paddingLeft: "10rem" }}>
          <div className="mb-2">
            <Card text="지속 가능한 준공영제의 합리적인 운용방안 구축" />
          </div>
          <div className="mb-2">
            <Card text="지속 가능한 시내버스 표준운송원가 산정기준 및 정산방안 구축" />
          </div>
          <div className="mb-2">
            <Card text="지자체별 버스업체 경영 및 서비스 평가 기준 수립" />
          </div>
          <div className="mb-2">
            <Card text="대중교통 요금조정 및 수입금 창출 방안 조사 분석" />
          </div>
          <div className="mb-2">
            <Card text="지역별 대중교통 정책방향 및 교통문제 해소방안 연구" />
          </div>
          <div className="mb-2">
            <Card text="기타 대중교통의 지속 가능한 발전을 위한 제반 연구 등" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Services;
