import { Link, animateScroll as scroll } from "react-scroll";

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="side1">
            <div className="row">
              <div className="col-md-3">
                <h1 className="logo">디오모빌리티</h1>
                <p className="footer-text">
                  Sustainable Developement of Public Transport
                </p>
              </div>
            </div>
          </div>
          <div className="side2">
            <div className="row" style={{ textAlign: "left" }}>
              <div className="col-md-3">
                <p className="footer-title">연락처</p>
                <ul>
                  <li>
                    <Link to="#">gsdo@domobility.co.kr</Link>
                  </li>
                  <li>
                    <Link to="#"> 전화: 02) 6326-7812</Link>
                  </li>
                  <li>
                    <Link to="#"> 팩스: 02) 6326-7813</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button onClick={() => scroll.scrollToTop(2500)} src="" className="gotop">
        <i className="fas fa-level-up-alt"></i>
      </button>
    </footer>
  );
}
export default Footer;
