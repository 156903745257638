function About() {
  return (
    <>
      <div
        style={{
          marginTop: "15rem",
          width: "100%",
          height: "10px",
          // paddingTop: "3rem",
        }}
        className="about-scroll"
      ></div>

      <div className="container about" style={{ paddingTop: "4rem" }}>
        <div>
          <h2
            className="main-title about-h2 text-center"
            style={{ paddingBottom: "4rem" }}
          >
            대표이사 인사말
          </h2>
          <p className="main-p">
            <span className="highlight-text1">(주)디오모빌리티는</span> 대한민국
            대중교통 서비스의 지속 가능한 발전을 위해 설립된 회사입니다.
          </p>
          <p className="main-p">
            2004년 7월 1일, 전국 최초로 시내버스 준공영제를 도입 시행한
          </p>
          <p className="main-p">
            수도 서울의{" "}
            <span className="highlight-text2">대중교통체계개편(준공영제)</span>
            에 따른 실행방안 연구를 시작으로
          </p>
          <p className="main-p">
            이들 사업의 안정적인 운영관리와 광역자치단체(부산, 대구, 인천, 광주,
            대전) 및 기초자치단체(창원, 청주, 춘천)와 제주특별자치도 등
          </p>
          <p className="main-p">
            준공영제를 도입 시행하는데 있어 20년이 넘는 기간 적극적인 참여와
            지원을 추진해온 대표이사의 경험과 노하우를 바탕으로
          </p>
          <p className="main-p">
            각 지역의 최적화된 준공영제가 안정적으로 도입 시행되고, 운영
            유지관리될 수 있도록
          </p>
          <p className="main-p">
            대한민국 버스운송사업에 기반을 둔 대중교통의 지속 가능한 발전을 위한
            적극적인 연구 서비스를 제공하겠습니다.
          </p>
          <p className="main-l">(주)디오모빌리티 대표이사 도군섭</p>
        </div>
      </div>
    </>
  );
}
export default About;
