function Header() {
  return (
    <header>
      <h1 style={{ fontFamily: "Roboto", fontStyle: "italic" }}>
        Sustainable Developement of Public Transport
      </h1>
      <h2 tyle={{ fontFamily: "sans-serif" }}>DO MOBILITY</h2>

      <div className="headerbg"></div>
    </header>
  );
}
export default Header;
